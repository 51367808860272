import React from 'react';
import {Link, graphql} from 'gatsby';
import {Helmet} from 'react-helmet';
import Layout from '../components/layout';
import {useSiteMetadata} from '../utils/use-site-metadata';

export default function Tags({data}) {
  const {tags} = data.allMarkdownRemark;
  const {title, description, siteUrl} = useSiteMetadata();

  return (
    <Layout>
      <Helmet>
        <title>Tags — {title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={siteUrl} />
      </Helmet>

      <ul className="tags-listing">
        {tags.map((tag) => {
          return (
            <li key={tag.fieldValue}>
              <Link
                className={`tags-listing__item tags-${tag.fieldValue}`}
                to={`/tags/${tag.fieldValue}`}
              >
                {tag.fieldValue}
              </Link>
            </li>
          );
        })}
      </ul>
    </Layout>
  );
}

export const pageQuery = graphql`
  query TagsQuery {
    allMarkdownRemark(
      sort: {order: DESC, fields: [frontmatter___title]}
      limit: 1000
    ) {
      tags: group(field: frontmatter___tags) {
        fieldValue
      }
    }
  }
`;
